import { formatISO, isValid, parseISO } from 'date-fns'
import { diff } from 'deep-object-diff'
import {
	filter,
	isDeepEqual,
	isTruthy,
	map,
	pick,
	pipe,
	unique,
	uniqueWith,
} from 'remeda'
import { fromZodError } from 'zod-validation-error'

import { getFollowerChanges } from '../helpers/followers'
import {
	Task,
	User,
	Workflow,
	WorkflowData,
	WorkflowDataStep,
	WorkflowDataStepCompleted,
} from '../types'
import { reduceToStepsWithRequirements } from '../workflows'
import { WorkflowStep } from '../workflows/workflow-schema'
import {
	validateTaskAssign,
	validateTaskDelete,
	validateTaskDescr,
	validateTaskDueDate,
	validateTaskDuration,
	validateTaskFollower,
	validateTaskMove,
	validateTaskMultiple,
	validateTaskOwner,
	validateTaskPriority,
	validateTaskSchedule,
	validateTaskStatus,
	validateTaskTitle,
	validateTaskUpdate,
	validateTaskWorkflow,
} from './parsers'
import { TaskActivity } from './task-activity-schemas'
import { taskLogDataFields } from './taskLogs'

const diffRequirements = (
	workflow: Workflow,
	oldSteps: WorkflowStep[],
	newSteps: WorkflowStep[]
) => {
	const newDiff = diff(oldSteps, newSteps) as Record<
		string,
		WorkflowStep | undefined
	>
	const oldDiff = diff(newSteps, oldSteps) as Record<
		string,
		WorkflowStep | undefined
	>

	// Filter out any undefined values for new steps. When a workflow changes,
	// the old steps may not align with new steps.
	for (const key in newDiff) {
		if (newDiff[key] === undefined) {
			delete newDiff[key]
		}
	}

	return { new: newDiff, old: oldDiff }
}

const getStepData = (
	workflow: Workflow,
	workflowData: WorkflowData
): Partial<WorkflowStep & { index?: number }> | null => {
	const activeStepId = workflowData?.activeStepId
	const index = workflow?.steps.findIndex((step) => step.id === activeStepId)
	if (workflow && index > -1) {
		return {
			index,
			...workflow.steps[index],
		}
	}

	return null
}

const getRequirementsChange = (
	workflow: Workflow,
	oldValue: WorkflowData | null,
	newValue: WorkflowData
) => {
	if (!workflow) {
		return {
			hasRequirementsChanged: false,
			requirementsChange: null,
		}
	}

	const oldStepData = oldValue?.stepData || {}
	const newStepData = newValue?.stepData || {}

	// If there is no old data, default values to the worflow source. This is so
	// the diff check gets the correct changes.
	Object.keys(newStepData).forEach((stepId) => {
		if (
			oldStepData[stepId] &&
			Object.keys(oldStepData[stepId]).length === 0
		) {
			const workflowStep = workflow?.steps.find(
				(step) => step.id === stepId
			)
			oldStepData[stepId] = workflowStep
		}
	})

	const oldSteps = reduceToStepsWithRequirements(workflow, oldStepData)
	const newSteps = reduceToStepsWithRequirements(workflow, newStepData)

	const requirementsChange = {
		newSteps,
		...diffRequirements(workflow, oldSteps, newSteps),
	}

	const hasRequirementsChanged =
		Object.values(requirementsChange.new).length > 0 &&
		!isDeepEqual(requirementsChange.old, requirementsChange.new)

	return {
		hasRequirementsChanged,
		requirementsChange,
	}
}

// Keeping this around for later:
// Simplified the workflow to get the core design right. Can add
// complexity in new iterations when needed.
// const getWorkflowData = async (
// 	key,
// 	oldValue,
// 	newValue,
// 	ctx,
// 	performingUserId
// ) => {
// 	const user = await ctx.dataSource.users.getById(Syslog(), performingUserId)
// 	const data = {
// 		userId: performingUserId,
// 		workflowId: newValue && newValue.id,
// 		referenceMap: { workflows: {}, steps: {} },
// 	}
// 	const workflows = await ctx.dataSource.workflows.list(user.organisationId)
// 	const getWorkflow = id => workflows.find(w => w.id === id)
// 	const getWorkflowTitle = id => getWorkflow(id).title
// 	const getWorkflowStep = (workflowId, stepId) => {
// 		const workflow = getWorkflow(workflowId)
// 		const step = workflow.steps.find(s => s.id === stepId)
// 		return {
// 			title: step.title,
// 			index: workflow.steps.indexOf(step),
// 			id: stepId,
// 		}
// 	}
// 	if ((oldValue && oldValue.childId) !== (newValue && newValue.childId)) {
// 		data.newChildId = newValue.childId
// 		if (newValue.childId) {
// 			data.referenceMap.workflows[newValue.childId] = {
// 				title: getWorkflowTitle(newValue.childId),
// 			}
// 		}
// 	}
// 	if ((oldValue && oldValue.id) !== (newValue && newValue.id)) {
// 		data.newId = newValue.id
// 		if (newValue.id) {
// 			data.referenceMap.workflows[newValue.id] = {
// 				title: getWorkflowTitle(newValue.id),
// 			}
// 		}
// 	}
// 	if (
// 		(!!oldValue &&
// 			!!oldValue.id &&
// 			getActiveStepId(getWorkflow(oldValue.id), oldValue)) !==
// 		(!!newValue &&
// 			!!newValue.id &&
// 			getActiveStepId(getWorkflow(newValue.id), newValue))
// 	) {
// 		const oldStepId = getActiveStepId(getWorkflow(newValue.id), oldValue)
// 		if (
// 			(oldValue && oldValue.id) === (newValue && newValue.id) &&
// 			oldStepId
// 		) {
// 			data.oldActiveStepId = oldStepId
// 			data.referenceMap.steps[data.oldActiveStepId] = getWorkflowStep(
// 				oldValue.id,
// 				oldStepId
// 			)
// 		}

// 		if (newValue.id) {
// 			data.newActiveStepId = getActiveStepId(
// 				getWorkflow(newValue.id),
// 				newValue
// 			)
// 			data.referenceMap.steps[data.newActiveStepId] = getWorkflowStep(
// 				newValue.id,
// 				data.newActiveStepId
// 			)
// 		}
// 	}
// 	let newCompleted
// 	let newUnCompleted
// 	if (newValue.id) {
// 		newCompleted =
// 			oldValue && oldValue.steps
// 				? newValue.steps
// 					? newValue.steps.filter(
// 							newStep =>
// 								!oldValue.steps.find(
// 									oldStep => oldStep.id === newStep.id
// 								) ||
// 								oldValue.steps.find(
// 									oldStep =>
// 										oldStep.id === newStep.id &&
// 										!oldStep.completed &&
// 										newStep.completed
// 								)
// 					  )
// 					: []
// 				: newValue.steps || []
// 		newUnCompleted =
// 			newValue.steps && oldValue && oldValue.steps
// 				? oldValue.steps.filter(
// 						oldStep =>
// 							!newValue.steps.find(
// 								newStep => newStep.id === oldStep.id
// 							) ||
// 							newValue.steps.find(
// 								newStep =>
// 									newStep.id === oldStep.id &&
// 									oldStep.completed &&
// 									!newStep.completed
// 							)
// 				  )
// 				: []

// 		for (const step of [...newCompleted, ...newUnCompleted]) {
// 			data.referenceMap.steps[step.id] = getWorkflowStep(
// 				newValue.id,
// 				step.id
// 			)
// 		}
// 	}

// 	const value = validateData(types.WORKFLOW, {
// 		...data,
// 		...(newCompleted && newCompleted.length ? { newCompleted } : {}),
// 		...(newUnCompleted && newUnCompleted.length ? { newUnCompleted } : {}),
// 	})

// 	return { type: types.WORKFLOW, data: value }
// }
//
const formatDate = (date: string) => {
	const parsedDate = parseISO(date)

	if (isValid(parsedDate)) {
		return formatISO(parsedDate, { representation: 'date' })
	}

	return null
}

const getUserMeta = (userId: string) => ({
	id: userId,
	name: '',
	nickname: '',
	gravatar: '',
})

const getTaskLogData = (
	performingUserId: string,
	oldTask: Task,
	newTask: Task,
	extra: {
		comment?: string
		isRepeatResurrect?: boolean
		removedWorkflowId?: string
		removedWorkflowTitle?: string
		user?: User
		workflow?: Workflow
	} = {}
): TaskActivity | null => {
	if (extra?.user && extra.user?.id !== performingUserId) {
		throw new Error(
			'getTaskLogData: Expected extra.user.id to match performingUserId'
		)
	}

	const changedFields = (oldTask: Task, newTask: Task) =>
		filter(
			unique([
				...Object.keys(pick(newTask, taskLogDataFields)),
				...Object.keys(pick(oldTask, taskLogDataFields)),
			]),
			(key: string) => !isDeepEqual(oldTask[key], newTask[key])
		)

	const diffKeys = changedFields(oldTask, newTask)
	const diffFields = diffKeys.map((key: string) => [
		key,
		oldTask[key],
		newTask[key],
	])

	// Map through changed fields and add data where field names match the
	// switch case.
	const taskLogs = pipe(
		diffFields,
		map(([key, oldValue, newValue]) => {
			switch (key) {
				case 'assigneeId': {
					const result = validateTaskAssign({
						type: 'task.assign',
						userId: performingUserId,
						assigneeId: newValue,
						meta: {
							assignee: getUserMeta(newValue),
							user: getUserMeta(performingUserId),
						},
					})
					if (result.success) {
						return result.data
					} else {
						throw fromZodError(result.error)
					}
				}

				case 'currentTimer':
					return null

				case 'descr': {
					const result = validateTaskDescr({
						type: 'task.descr',
						userId: performingUserId,
						meta: { user: getUserMeta(performingUserId) },
					})
					if (result.success) {
						return result.data
					} else {
						throw fromZodError(result.error)
					}
				}

				case 'dueDate':
				case 'dueTime': {
					// TODO: handle recurring due dates
					const result = validateTaskDueDate({
						type: 'task.dueDate',
						userId: performingUserId,
						date: newTask.dueDate
							? formatDate(newTask.dueDate)
							: null,
						time: newTask.dueTime ? newTask.dueTime : null,
						isRecurring: false,
						meta: { user: getUserMeta(performingUserId) },
					})
					if (result.success) {
						return result.data
					} else {
						throw fromZodError(result.error)
					}
				}

				case 'hoursAllocated': {
					const getDuration = (val: string | number) => {
						const number = Number(val)
						return isNaN(number) ? 0 : Math.round(number * 3600)
					}

					const result = validateTaskDuration({
						type: 'task.duration',
						userId: performingUserId,
						oldDuration: getDuration(oldValue),
						newDuration: getDuration(newValue),
						meta: { user: getUserMeta(performingUserId) },
					})
					if (result.success) {
						return result.data
					} else {
						throw fromZodError(result.error)
					}
				}

				case 'followers': {
					const changes = getFollowerChanges(oldValue, newValue)
					const followerMeta = changes.added
						? getUserMeta(changes.added[0].id)
						: changes.removed
							? getUserMeta(changes.removed[0].id)
							: null
					const followerId = changes.added
						? changes.added[0].id
						: null
					const role = changes.added ? changes.added[0].roles : null
					const removeFollowerId = changes.removed
						? changes.removed[0].id
						: null
					// Either followerId and role are set together or removeFollowerId is set.
					if (
						// If followerId, then role must be non-null and removeFollowerId must be null
						(followerId !== null &&
							role !== null &&
							removeFollowerId == null) ||
						// If followerId is null, then role must be null and removeFollowerId must be non-null
						((followerId === null || role === null) &&
							removeFollowerId !== null)
					) {
						const result = validateTaskFollower({
							type: 'task.follower',
							userId: performingUserId,
							followerId,
							role,
							removeFollowerId,
							meta: {
								follower: followerMeta,
								user: getUserMeta(performingUserId),
							},
						})
						if (result.success) {
							return result.data
						} else {
							throw fromZodError(result.error)
						}
					} else {
						return null
					}
				}

				case 'ownerId': {
					const result = validateTaskOwner({
						type: 'task.owner',
						userId: performingUserId,
						ownerId: newValue,
						meta: {
							owner: getUserMeta(newValue),
							user: getUserMeta(performingUserId),
						},
					})
					if (result.success) {
						return result.data
					} else {
						throw fromZodError(result.error)
					}
				}

				case 'parentId': {
					const getParentTitle = (
						task: Pick<Task, 'parents' | 'title'>
					) => {
						const parent =
							task.parents?.length > 0 &&
							task.parents[task.parents?.length - 1]
						return parent ? parent.title : 'Root'
					}
					const result = validateTaskMove({
						type: 'task.move',
						userId: performingUserId,
						oldParentId: oldValue,
						newParentId: newValue,
						oldParentTitle: getParentTitle(oldTask),
						newParentTitle: getParentTitle(newTask),
						meta: {
							oldTask: {
								id: oldValue,
								title: getParentTitle(oldTask),
							},
							newTask: {
								id: newValue,
								title: getParentTitle(newTask),
							},
							user: getUserMeta(performingUserId),
						},
					})
					if (result.success) {
						return result.data
					} else {
						throw fromZodError(result.error)
					}
				}

				case 'startDate':
				case 'startTime': {
					// TODO: handle recurring start dates
					const result = validateTaskSchedule({
						type: 'task.schedule',
						userId: extra?.isRepeatResurrect
							? '0'
							: performingUserId,
						date: newTask.startDate
							? formatDate(newTask.startDate)
							: null,
						time: newTask.startTime ? newTask.startTime : null,
						isRecurring: false,
						meta: { user: getUserMeta(performingUserId) },
					})
					if (result.success) {
						return result.data
					} else {
						throw fromZodError(result.error)
					}
				}

				case 'statusCode': {
					if (newValue === 'deleted') {
						const result = validateTaskDelete({
							type: 'task.delete',
							userId: performingUserId,
							meta: { user: getUserMeta(performingUserId) },
						})
						if (result.success) {
							return result.data
						} else {
							throw fromZodError(result.error)
						}
					} else if (newValue === 'done') {
						// Do nothing here, it needs to be handled elsewhere.
						return null
					} else {
						const result = validateTaskStatus({
							type: 'task.status',
							userId: performingUserId,
							status: newValue,
							message: extra.comment || null,
							meta: { user: getUserMeta(performingUserId) },
						})
						if (result.success) {
							return result.data
						} else {
							throw fromZodError(result.error)
						}
					}
				}

				case 'title': {
					// If there is a missing title then don't create a taskLog
					if (!oldValue || !newValue) return null

					const result = validateTaskTitle({
						type: 'task.title',
						userId: performingUserId,
						oldTaskTitle: oldValue,
						newTaskTitle: newValue,
						meta: { user: getUserMeta(performingUserId) },
					})
					if (result.success) {
						return result.data
					} else {
						throw fromZodError(result.error)
					}
				}

				case 'importance':
				case 'urgency': {
					const result = validateTaskPriority({
						type: 'task.priority',
						userId: performingUserId,
						oldImportance: key === 'importance' ? oldValue : null,
						newImportance: key === 'importance' ? newValue : null,
						oldUrgency: key === 'urgency' ? oldValue : null,
						newUrgency: key === 'urgency' ? newValue : null,
						meta: { user: getUserMeta(performingUserId) },
					})
					if (result.success) {
						return result.data
					} else {
						throw fromZodError(result.error)
					}
				}

				case 'workflowData': {
					if (!extra?.user || !extra?.workflow) {
						throw new Error(
							'getTaskLogData: Expected a user and workflow entry in extra'
						)
					}
					const oldWorkflowData: WorkflowData | null = oldValue
					const newWorkflowData: WorkflowData | null = newValue
					if (newWorkflowData) {
						const { hasRequirementsChanged, requirementsChange } =
							getRequirementsChange(
								extra?.workflow,
								oldWorkflowData,
								newWorkflowData
							)

						// Get completed steps
						const stepDataSteps: WorkflowDataStep[] = Object.values(
							newWorkflowData.stepData || {}
						)
						const completedSteps = stepDataSteps.reduce<
							(WorkflowDataStepCompleted & {
								id: string
							})[]
						>((acc, step) => {
							const oldStepCompleted =
								oldWorkflowData?.stepData?.[step.id]?.completed
							if (
								step.completed &&
								(!oldStepCompleted ||
									!isDeepEqual(
										oldStepCompleted,
										step.completed
									))
							) {
								const completed = step.completed
								acc.push({ id: step.id, ...completed })
							}
							return acc
						}, [])

						const completedComment = completedSteps.reduce<
							string | undefined
						>((acc, { id }) => {
							const step = newWorkflowData.stepData?.[id]
							const stepCompleted = step?.completed
							if (stepCompleted?.comment && !acc) {
								return stepCompleted.comment
							}
							return acc
						}, undefined)

						const workflow = extra.workflow
						const newStepData =
							getStepData(workflow, newWorkflowData) || {}
						const oldStepData =
							oldWorkflowData?.id && !oldValue?.activeStepId
								? getStepData(workflow, {
										...oldWorkflowData,
										activeStepId: workflow?.steps[0].id,
									})
								: getStepData(workflow, oldWorkflowData)
									? oldWorkflowData.stepData[
											oldWorkflowData.activeStepId
										]
									: {}

						const result = validateTaskWorkflow({
							type: 'task.workflow',
							userId: performingUserId,
							workflowId:
								workflow?.id ??
								newWorkflowData.id ??
								newWorkflowData.childId,
							workflowTitle: workflow?.title,
							newStepId: newStepData.id ?? null,
							newStepIndex: newStepData.index ?? null,
							newStepTitle: newStepData.title ?? null,
							oldStepId: oldStepData.id ?? null,
							oldStepIndex: oldStepData.index ?? null,
							oldStepTitle: oldStepData.title ?? null,
							removedWorkflowId: extra.removedWorkflowId ?? null,
							removedWorkflowTitle:
								extra.removedWorkflowTitle ?? null,
							hasRequirementsChanged,
							requirementsChange: hasRequirementsChanged
								? requirementsChange
								: null,
							completedSteps,
							comment: completedComment ?? extra.comment ?? null,
							meta: {
								user: {
									id: extra.user.id,
									name: extra.user.name,
									nickname: extra.user.nickname,
									gravatar: extra.user.gravatar,
								},
								workflow: {
									id: workflow.id,
									title: workflow.title,
								},
							},
						})
						if (result.success) {
							return result.data
						} else {
							throw fromZodError(result.error)
						}
					} else {
						return null
					}
				}

				default: {
					const result = validateTaskUpdate({
						type: 'task.update',
						userId: performingUserId,
						meta: { user: getUserMeta(performingUserId) },
					})
					if (result.success) {
						return result.data
					} else {
						throw fromZodError(result.error)
					}
				}
			}
		}),
		filter(isTruthy),
		uniqueWith(isDeepEqual)
	)

	// If there is a workflow taskLog, then just show that one.
	const workflow = taskLogs.find(
		(activity: TaskActivity) => activity.type === 'task.workflow'
	)
	if (workflow) {
		return workflow
	}

	const assignee = taskLogs.find(
		(activity: TaskActivity) => activity.type === 'task.assign'
	)
	if (assignee) {
		return assignee
	}

	if (taskLogs.length > 1) {
		const result = validateTaskMultiple({
			type: 'task.multiple',
			userId: extra.isRepeatResurrect ? '0' : performingUserId,
			taskLogs,
			meta: { user: getUserMeta(performingUserId) },
		})
		if (result.success) {
			return result.data
		} else {
			throw fromZodError(result.error)
		}
	}

	return taskLogs[0]
}

export { getRequirementsChange, getTaskLogData }
